.wrapper {
  @apply flex flex-col items-center justify-center;
}

.formWrapper {
  @apply w-[365px];
}

.title {
  @apply font-bold font-os text-xl mb-5;
}

.mailInput {
  @apply w-[365px] h-10;
}

.inputTitle {
  @apply font-semibold font-os text-xs mb-[10px];
}

.loginButton {
  @apply mt-5 h-10;
}

.signUp {
  @apply flex justify-center font-normal text-sm mt-5;
}

.wrongUser {
  @apply font-semibold font-os text-redOrange block text-base mb-[10px];
}

.info {
  @apply text-sm mb-5;
}

.inputClass {
  @apply text-sm;
}

.backLink {
  @apply font-bold text-apple-light text-sm ml-[2px];
}

.reCaptcha {
  @apply mt-[27px] mb-5;
}

.successWrapper {
  @apply text-center w-[372px];
}

.icon {
  @apply flex justify-center mb-[15px];
}

.signUp {
  @apply flex justify-center font-normal text-sm mt-5;
}

.loading {
  @apply border-2 border-alto w-[30px] h-[30px];
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
}

.againRecaptcha {
  @apply invisible;
}

.formImage {
  @apply rounded-md;
}
