.wrapper {
  @apply text-center;
  width: 355px;
}

.title {
  @apply font-bold font-os;
  font-size: 18px !important;
}

.info {
  font-size: 14px !important;
  margin: 15px 0;
}

.activationButton {
  height: 40px;
}

.icon {
  @apply flex justify-center;
  margin-bottom: 15px;
}
