.wrapper {
  @apply flex flex-col items-center	w-[415px] text-center px-[60px];
}

.icon {
  @apply mb-5;
}

.title {
  @apply text-lg font-bold mb-3;
}

.description {
  @apply text-sm leading-[23px];
}

.useDifferentAccountButton button {
  @apply border-none font-bold text-sm mt-2;
}
