.wrapper {
  @apply flex flex-col;
  width: 355px;
}

.title {
  @apply flex text-black font-bold;
  font-size: 18px;
  line-height: 25px;
}

.inner {
  @apply flex flex-col relative;
  margin-top: 15px;
}

.info {
  @apply text-black;
  font-size: 14px;
  line-height: 19px;
}

.action {
  @apply flex flex-row cursor-pointer;
}

.action > * {
  transition: all 0.2s ease-in-out;
  margin-top: 20px;
}

.actionText {
  @apply text-apple-light font-bold text-sm;
  padding-left: 5px;
}

.action:hover .actionText {
  @apply text-apple-dark;
}

.action:hover path {
  fill: theme("colors.apple.dark");
}

.formWrap {
  @apply flex flex-row items-center;
}

.imageWrap {
  @apply border border-solid border-gallery flex items-center justify-center w-12 h-12;
  border-radius: 4px;
}

.textWrap {
  padding-left: 11px;
  width: calc(100% - 48px); /* 48 => .imageWrap width */
}

.textWrap input {
  @apply border-none;
}

.formActions {
  @apply flex flex-col;
  margin-top: 25px;
}

.textLink {
  @apply text-apple-light font-bold flex justify-center cursor-pointer text-sm;
  margin-top: 15px;
  transition: all 0.2s ease-in-out;
}

.textLink:hover {
  @apply text-apple-dark;
}

.favoriteGroups {
  @apply flex flex-col;
}

.favoriteGroups > li {
  @apply flex flex-row cursor-pointer;
}

.favoriteGroups > li + li {
  margin-top: 15px;
}

.listInfoWrap {
  @apply flex flex-col pl-2.5;
}

.groupName {
  @apply text-black text-sm;
  margin-bottom: 3px;
}

.groupNumber {
  @apply text-gray whitespace-nowrap overflow-hidden text-ellipsis;
  font-size: 13px;
  line-height: 18px;
}

.loaderWrap {
  @apply flex justify-center items-center w-full h-full absolute bg-standarts-white z-10 left-0 top-0 opacity-0 invisible;
  transition: all 0.2s ease-in-out;
}

.loaderWrapViewed {
  @apply visible opacity-100;
}

.error {
  @apply text-xs text-redOrange mt-[4px];
}
