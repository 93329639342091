.wrapper {
  @apply fixed w-full left-0 flex justify-center;
  padding-bottom: 10px;
  z-index: 110;
  bottom: -70px;
  transition: all 0.2s ease-in-out;
}

.wrapperOpened {
  @apply bottom-0;
}

.inner {
  @apply border border-solid border-alto flex flex-row items-center bg-white;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 49px;
  padding: 0 15px;
}

.iconWrap {
  padding-right: 8px;
}

.content {
  @apply text-standarts-black;
  font-size: 13px;
  line-height: 18px;
}

.content > * {
  @apply font-semibold;
}

.action {
  @apply font-semibold text-standarts-black underline cursor-pointer;
  font-size: 12px;
  line-height: 16px;
  margin-left: 42px;
}
