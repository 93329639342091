.wrapper {
  @apply h-full w-full relative;
}
.input {
  @apply h-full w-full focus:outline-none focus:border-alto rounded bg-white py-2 pl-3 text-left border  border-alto font-os;
  padding-left: 15px;
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.passwordInput {
  padding-right: 70px;
}

.error {
  @apply border border-redOrange;
}

.errorMessage {
  @apply text-redOrange;
  font-size: 12px;
}

.passwordButton {
  @apply absolute h-full inline-flex items-center font-bold font-os text-apple-light;
  right: 15px;
  font-size: 14px;
}
