.wrapper {
  @apply flex flex-col items-center justify-center;
}

.formWrapper {
  @apply w-[365px];
}

.title {
  @apply font-bold font-os text-[20px] mb-5;
}

.mailInput {
  @apply w-[365px] h-10;
}

.inputTitle {
  @apply font-semibold font-os text-xs mb-[10px];
}

.passwordField {
  @apply mt-[15px];
}

.forgetPassword {
  @apply flex justify-end mt-5;
}

.forgetPasswordLink {
  @apply font-bold text-apple-light text-sm ml-[2px];
}

.loginButton {
  @apply mt-5 h-10;
}

.signUp {
  @apply flex justify-center font-normal text-sm mt-5;
}

.wrongUser {
  @apply font-semibold font-os text-redOrange block text-base mb-[10px];
}

.loginInput {
  @apply text-sm;
}

.isHidden {
  @apply hidden;
}

.signUpQuestion {
  @apply mr-2;
}

.recaptchaWrapper {
  @apply mt-[15px] mb-[20px];
}
