.dropdown {
  @apply relative w-full h-full cursor-default rounded  text-left border  border-alto font-os flex justify-between items-center bg-white text-[15px] pl-[15px] pr-[11px];
}

.dropdown:disabled,
.dropdown[disabled] {
  @apply bg-wild-sand;
}

.list {
  @apply absolute mt-1 max-h-60 z-50 overflow-auto rounded bg-white border  border-alto font-os cursor-pointer text-sm;
}

.option {
  @apply hover:bg-alto font-normal p-[10px_15px] text-sm;
}

.wildsand {
  @apply bg-wild-sand;
}

.icon {
  @apply absolute right-[3px];
}

.placeholder {
  @apply text-gray font-os text-[15px];
}

.dropdown[data-headlessui-state="open"] > .icon {
  @apply absolute rotate-180 right-[3px];
}

.buttonContent {
  @apply whitespace-nowrap overflow-hidden;
}

.errorMessage {
  @apply absolute text-redOrange text-xs;
}

.error {
  @apply border border-redOrange;
}

.isVisiblePlaceHolder {
  @apply text-gray;
}
