.buttonClass {
  @apply w-full;
}
.isTextView {
  button {
    @apply w-auto rounded-none border-inherit font-semibold;
    background: none !important;
    color: theme("colors.apple.light");
    &:hover {
      background: none !important;
    }
  }
}
