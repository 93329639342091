.label {
  @apply flex;
}
.input {
  @apply hidden;
}

.input + .display {
  @apply border-alto border-[1.5px] rounded min-w-[20px] h-[20px] box-border inline-block relative mr-[10px] mt-[1px];
}

.input + .showCheckBoxFooter {
  @apply ml-5;
}

.title {
  width: calc(100% - 12px);
  box-sizing: border-box;
}

.titleWithShowImage {
  @apply w-auto text-sm ml-[3px];
}

.positionClass .input + .display {
  @apply absolute;
}

.input:checked + .display::after {
  @apply h-[14px] w-[14px] absolute rounded-sm top-[50%] left-[50%] border-none bg-apple-light translate-x-[-50%] translate-y-[-50%];
  content: "";
}

.checkBoxLength {
  @apply pl-[4px] text-sm;
}

.badge {
  @apply flex items-center justify-center w-[51px] h-[22px] p-[4px_6px] border-[1px] border-solid border-flushOrange bg-alabaster text-flushOrange font-bold text-[12px] rounded-[4px];
  animation: pulse 1.3s infinite;
  gap: 5px;
  flex-shrink: 0;
}

@keyframes pulse {
  0%,
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dot {
  @apply min-w-[5px] h-[5px] rounded-full bg-flushOrange;
}
